/**
 *  Default Google Map init
 */

var Cromology = Cromology || {};
Cromology.maps = (function ($, self) {
  var isInit = false,
    defer = $.Deferred();
  function init() {
    if (isInit) return true;
    $.getScript('https://maps.googleapis.com/maps/api/js?libraries=places&callback=CromologyMapsInit&key=' + Zolpan.vars.google_api_key);
    isInit = true;
  }
  function onLoad(callback) {
    init();
    defer.done(callback);
  }
  function loading() {
    defer.resolve();
  }
  return $.extend(self, {
    init: init,
    loading: loading,
    onLoad: onLoad,
  });
})(jQuery, {});
CromologyMapsInit = Cromology.maps.loading;

(function ($) {
  'use strict';

  $(window).ready(init);

  // Constants
  var WIDGET_SEL = '[data-map]';
  var WIDGET_MAP_SEL = '[data-map-canvas]';
  var WIDGET_SEARCH_SEL = '[data-map-search]';
  var WIDGET_BRANDS_SEL = '[data-map-brands]';
  var WIDGET_RESET_SEL = '[data-map-reset]';
  var WIDGET_GEOLOCATE = '[data-map-geolocate]';
  var WIDGET_TOLLENS_SEL = '[data-map-tollens]';
  var MAP;
  var MAPSTYLE = [{
    "featureType": "administrative",
    "elementType": "labels.text.fill",
    "stylers": [{"color": "#444444"}]
  }, {"featureType": "landscape", "elementType": "all", "stylers": [{"color": "#f2f2f2"}]}, {
    "featureType": "poi",
    "elementType": "all",
    "stylers": [{"visibility": "off"}]
  }, {"featureType": "poi", "elementType": "labels.text", "stylers": [{"visibility": "off"}]}, {
    "featureType": "road",
    "elementType": "all",
    "stylers": [{"saturation": -100}, {"lightness": 45}]
  }, {
    "featureType": "road.highway",
    "elementType": "all",
    "stylers": [{"visibility": "simplified"}]
  }, {
    "featureType": "road.arterial",
    "elementType": "labels.icon",
    "stylers": [{"visibility": "off"}]
  }, {"featureType": "transit", "elementType": "all", "stylers": [{"visibility": "off"}]}, {
    "featureType": "water",
    "elementType": "all",
    "stylers": [{"color": "#dbdbdb"}, {"visibility": "on"}]
  }];

  var MARKERPATH = 'M.4-30c3.8 0 7 3 7 7 0 3.7-3 7-7 7-3.8 0-7-3.2-7-7 0-4 3-7 7-7M-1.4-1C-.7.3 1.4.3 2-1c0 0 8.4-12.3 10.3-16 2-4 2-8.2 0-12C9-35.8 1-38.4-5.7-35c-6.5 3.3-9 11.6-5.8 18 3 6.2 10 16 10 16';
  var INFOWINDOW;
  var DISPLAY_TOOLTIP = true;
  var markersList= [];
  var markers, mapIcon, markerPath, gmbMarkerPath, mainMarkerPath, mapZoom, mapOptions, mapCenter, cta, bounds, colors, isSearch, mapsLink;

  Translator.locale = $('html').attr('lang');
  /**
   * Init
   */
  function init() {
    var widget = $(WIDGET_SEL);
    if (widget.length === 0) {
      return;
    }
    Cromology.maps.onLoad(function(){
      setUpMap(widget);
      if (widget.find(WIDGET_SEARCH_SEL)) {
        initializeSearchBox();
      }
    });
  }

  // setup map options
  function setUpMap(widget) {
    markers = widget.data('map');
    mapZoom = widget.data('map-zoom') ? widget.data('map-zoom') : 6;
    mapCenter = widget.data('map-center');
    isSearch = widget.data('map-is-search');
    mapsLink = widget.data('map-maps-link') ? widget.data('map-maps-link') : '';

    if(isSearch){
      mapCenter = false;
    }

    cta = widget.data('map-tooltipcta');
    colors = widget.data('map-colors');
    mapIcon = widget.data('map-icon') || MARKERPATH;
    markers = $.map(markers, function (value) {
      return [value];
    });

    //color marker independant
    markerPath = {
      path: mapIcon,
      fillColor: colors.main,
      fillOpacity: 1,
      scale: 1.2,
      strokeWeight: 0
    };

    //color marker gmb
    gmbMarkerPath = {
      path: mapIcon,
      fillColor: colors.gmb,
      fillOpacity: 1,
      scale: 1.2,
      strokeWeight: 0
    };
    mainMarkerPath = {
      path: MARKERPATH,
      fillColor: colors.secondary,
      fillOpacity: 1,
      scale: 1.2,
      strokeWeight: 0
    };

    if(!mapCenter){
      mapOptions = {
        mapTypeControl: false,
        streetViewControl: false,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        styles: MAPSTYLE,
        maxZoom: 15,
        minZoom: 2,
        zoom: mapZoom
      };
    }else{
      mapOptions = {
        mapTypeControl: false,
        streetViewControl: false,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        styles: MAPSTYLE,
        maxZoom: 15,
        minZoom: 2,
        center: mapCenter,
        zoom: mapZoom
      };
    }

    bounds = new google.maps.LatLngBounds();
    INFOWINDOW = new google.maps.InfoWindow({content: ''});
    MAP = new google.maps.Map($(WIDGET_MAP_SEL)[0], mapOptions);
    DISPLAY_TOOLTIP = markers.length > 1 ? true : false;  // don't display tooltip if there is only one marker
    for (var i = 0; i < markers.length; i++) {
      createMarkerWithLocation(markers, i);
    }
  }

  // Display all markers then center and zoom map around
  function createMarkerWithLocation(locations, i) {
    // create markers on map
    var position;
    if (locations[i].latlng != undefined) {
      var lat = locations[i].latlng.split(',')[0];
      var lng = locations[i].latlng.split(',')[1];
      position = {lat: parseFloat(lat), lng: parseFloat(lng)};
    }
    var icon = markerPath
    // GMB stores should use different color
    if (locations[i].type === 'store') {
      icon = gmbMarkerPath
    }
    // if marker is not a store but a seach place
    if(locations[i].title == '') {
      DISPLAY_TOOLTIP = false
      icon = mainMarkerPath
    }
    var marker = new google.maps.Marker({
      icon: icon,
      position: position,
      map: MAP,
      title: locations[i].title,
      img: locations[i].img,
      detail: locations[i].address,
      email: locations[i].email,
      storeName: locations[i].storeName,
      phone: locations[i].phone,
      url: locations[i].url,
      animation: google.maps.Animation.DROP,
      displayTooltip: locations[i].displayTooltip,
      type: locations[i].type,
      lat: lat,
      lng: lng,
      brands: locations[i].brands,
      indep_custom_store_name: locations[i].indep_custom_store_name
    });
    markersList.push(marker);
    // manage centering and zoom
    bounds.extend(marker.getPosition());

    if (!mapCenter) {
      MAP.fitBounds(bounds);
    }

    // manage tooltip display
    if (DISPLAY_TOOLTIP) {
      tooltip(marker, cta);
    }
  }

  // Display tooltip
  function tooltip(marker, cta) {
    var storeName = marker.title;
    if(marker.type == 'independant_store') {
      if( marker.indep_custom_store_name !== '' ){
        storeName = marker.indep_custom_store_name;
      } else {
        storeName = marker.storeName;
      }
    }


    var storeMaps = marker.storeMaps;
    var lat = marker.lat;
    var long = marker.lng;

    //z for zoom in a map, t=m for maps, q for lat + lng
    var linkForStore = mapsLink + '?z=12&t=m&q=loc:' + lat +'+'+long;

    var contentHtml = '';

    //Tollens version
    if( $(WIDGET_TOLLENS_SEL).length > 0){
      contentHtml = '<div class="Map-tooltipContent">' +
      '<div class="Map-tooltipDetail">';

      if( marker.img !==''){
        contentHtml = contentHtml + '<img class="Map-tooltipImage" src="' + marker.img + '"/>';
      }

      contentHtml = contentHtml + '<h3 class="Map-tooltipTitle">' + storeName + '</h3>' +
      '<p>' + marker.detail + '</p>';

      if(marker.phone != '') {
        contentHtml = contentHtml + '<p>' + marker.phone + '</p>'
      }
  
      if(marker.email != '') {
        contentHtml = contentHtml + '<p>' + marker.email + '</p>'
      }

      contentHtml += '</div><div class="Map-tooltipButton"><p>';
      if(marker.type != 'independant_store') {
        contentHtml = contentHtml + '<a class="Map-tooltipBtn Button Button--tollensgreen js-store-tooltip-link" href="' + marker.url + '">' + cta + '</a>';
      }
      if(mapsLink != '') {
        var linkForStore = mapsLink + '?z=12&t=m&q=loc:' + lat +'+'+long;
        var buttonName = Translator.trans('map.maps.link');
        var contentHtml = contentHtml + '<a class="Map-tooltipBtn Button Button--tollensgreenrevert" href="' + linkForStore + '" target="_blank">' + buttonName + '</a>';
      }
      contentHtml += '</p></div>'

    } 
    //global version
    else {
      contentHtml = '<div class="Map-tooltipContent" style="width\:160px;">' +
      '<h3 class="Map-tooltipTitle">' + storeName + '</h3>' +
      '<div class="Map-tooltipDetail">' +
      '<img src="' + marker.img + '" style="width:120px;"/>' +
      '<p>' + marker.detail + '</p>';

      if(marker.phone != '') {
        contentHtml = contentHtml + '<p>' + Translator.trans('map.tooltip.phone') + ' ' + marker.phone + '</p>'
      }
  
      if(marker.email != '') {
        contentHtml = contentHtml + '<p>' + Translator.trans('map.tooltip.email') + ' ' + marker.email + '</p>'
      }
  
      if(marker.type != 'independant_store') {
        contentHtml = contentHtml + '<p><a class="Map-tooltipBtn Button Button--plain js-store-tooltip-link" href="' + marker.url + '">' + cta + '</a></p>'
      }

      if(mapsLink != '') {
        var linkForStore = mapsLink + '?z=12&t=m&q=loc:' + lat +'+'+long;
        var buttonName = Translator.trans('map.maps.link');
        var contentHtml = contentHtml + '<p><a class="Map-tooltipBtn Button Button--plain" href="' + linkForStore + '" target="_blank">' + buttonName + '</a></p>'
      }
    }

    if(marker.brands) {
      contentHtml = contentHtml + '<div>' +
        '<div class="Map-tooltipImgs">';

      for (var b = 0; b < marker.brands.length; b++) {
        contentHtml = contentHtml +
        '<img class="Map-tooltipImg" src="/assets/themes/claessens/images/logos/'+marker.brands[b]+'.png">';
      }
      contentHtml = contentHtml +  '</div></div>';
    }

    $('.gm-style-iw').parent().addClass("Map-tooltip");

    google.maps.event.addListener(marker, 'click', function () {
      INFOWINDOW.setContent(contentHtml);
      INFOWINDOW.open(MAP, marker);
    });
  }

  // Instantiate search box
  function initializeSearchBox() {
    var input = $(WIDGET_SEARCH_SEL);
    var submit = input.next('button');
    var restrictions = $.map(input.data('map-restriction'), function (value) {
      return [value];
    });
    var options = {
      componentRestrictions: {
        country: restrictions
      }
    }
    var searchBox = new google.maps.places.Autocomplete(input[0], options);
    // Brand filtering
    var select = $(WIDGET_BRANDS_SEL);

    var reset = $(WIDGET_RESET_SEL);
    reset.on('click', function() {
      select.val('');
      input.val('');
      submit.click();
    })

    select.on('change', function() {
      search(input.val(), searchBox, select.val());
    });
    searchBox.addListener('place_changed', function() {
      search(this.getPlace().formatted_address, searchBox, select.val())
    });
    $(submit).on('click', function() {
      search(input.val(), searchBox, select.val())
    });
    $(WIDGET_GEOLOCATE).on('click', function() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function(data) {
          var geocoder = new google.maps.Geocoder();
          geocoder.geocode( {'address': data.coords.latitude +","+ data.coords.longitude}, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
              input.val(results[0].formatted_address);
              search(input.val(), searchBox, select.val())
            }
          });
        });
      }
    });
  }

  // Search
  // brands - array of brands - can be undefined
  function search(value, searchBox, brands) {
    mapCenter = false;
    if (value == undefined) {
      value = searchBox.getPlace().name;
    }
    if(value == "") {
      value = "all";
    }
    var url = Routing.generate('ajax_storelocator_search') + "/" + value;
    if (brands && brands.length > 0) {
      url = url + '/' + brands;
    }
    $.get({
      url: url,
      success: function (markers) {
        deleteAllMArkers();
        bounds = new google.maps.LatLngBounds();
        DISPLAY_TOOLTIP = markers.length > 1 ? true : false;  // don't display tooltip if there is only one marker
        for (var i = 0; i < markers.length; i++) {
          createMarkerWithLocation(markers, i);
        }
      }
    });
  }

  // Delete all markers to reset before a special search
  function deleteAllMArkers() {
    for (var i = 0; i < markersList.length; i++) {
      markersList[i].setMap(null);
    }
    markersList = [];
  }


}(jQuery));
