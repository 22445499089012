// noinspection JSVoidFunctionReturnValueUsed

Zolpan.form.contact = (function ($) {

  var $contactForm = $("#FormContact");

  function init() {
    initCustomValidationRules();
    forbidSpaceInPhoneNumber();
    validateContactForm();
  }

  function forbidSpaceInPhoneNumber() {
    $contactForm.on('keyup', 'input[name$="form[phone]"]', function () {
      var $phoneInput = $('input[name$="form[phone]"]');
      $phoneInput.val($phoneInput.val().replace(" ", ""));
    });
  }

  function initCustomValidationRules() {
    jQuery.validator.addMethod("tollens_zipcode", function (value, element) {
      return this.optional(element) || /^\d{5}$/.test(value);
    }, 'Veuillez saisir un code postal valide.');

    jQuery.validator.addMethod("zolpan_phone", function (value, element) {
      Translator.locale = $('html').attr('lang');
      if(document.querySelector("[data-form-robbialac]")) {
        return this.optional(element) || /^351-\d{9}$/.test(value);
      }
      return this.optional(element) || /^(\+(\d){2}|0)\d{9}$/.test(value);
    }, Translator.trans('contact.phone.format') )
  }

  function validateContactForm() {
    $contactForm.validate({
      errorClass: "FormItem-info FormItem-info--error",
      rules: {
        "contact_tollens[zipCode]":{
          'tollens_zipcode': true
        },
        "contact[phone]": {
          'zolpan_phone': true
        }
      },
      errorPlacement: function (error, element) {
        if (element.parent(".FormChoice").length > 0) {
          error.appendTo($(element).closest('fieldset'));
        } else {
          error.insertAfter(element);
        }
        element.closest('.FormItem').addClass('FormItem--error');
      },
      unhighlight: function (element) {
        $(element.closest('.FormItem')).removeClass('FormItem--error');
      },
      submitHandler: function(form) {
        var submitButton = document.getElementById('FormContactButton');
        submitButton.setAttribute('disabled', 'disabled');
        form.submit();
      }
    });
  }

  $(document).ready(function () {
    if ($contactForm.length > 0) {
      init();
    }
  });

})(jQuery);
Zolpan.form = Zolpan.form || {};
